import {
  ISearchHookmanQualifiedListParams,
  ISearchHookmanQualifiedListResponse,
} from 'interfaces'

import { evAPI } from './api'

export const hookmanListAPI = evAPI.injectEndpoints({
  endpoints: builder => ({
    searchSafetyAccompanyingPersons: builder.query<
      ISearchHookmanQualifiedListResponse['data'],
      ISearchHookmanQualifiedListParams
    >({
      query: props => {
        return {
          url: 'Management/SearchSafetyAccompanyingPersonsListQuery',
          method: 'POST',
          body: { ...props },
        }
      },
      transformResponse: ({ data }: ISearchHookmanQualifiedListResponse) => data,
    }),
  }),
})

export const { useLazySearchSafetyAccompanyingPersonsQuery } = hookmanListAPI
