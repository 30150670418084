import { Box, Checkbox, FormGroup, Grid, Typography } from '@mui/material'
import { IHookmanState, IValueLabel } from 'interfaces'
import {
  selectHookmanSelectedFilters,
  setHookmanFilters,
  useAppDispatch,
  useAppSelector,
} from 'store'
import {
  CheckedIcon,
  StyledFormControlLabel,
  UncheckedIcon,
} from 'components/HookmanQualifiedList/Filters/styles'
import { useIntl } from 'react-intl'

interface LevelsActionsProps {
  levelOptions?: IValueLabel[]
  actionOptions?: IValueLabel[]
}

type FilterOptions = {
  name: string
  value: keyof IHookmanState['filters']
}

const LevelsActions = ({ levelOptions, actionOptions }: LevelsActionsProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const selectedFilters = useAppSelector(selectHookmanSelectedFilters)

  const filtersOptions: FilterOptions[] = [
    {
      name: intl.formatMessage({
        id: 'applicationTerm.certifiedLevel',
      }),
      value: 'certifiedLevel',
    },
    {
      name: intl.formatMessage({
        id: 'applicationTerm.workingLevel',
      }),
      value: 'workingLevel',
    },
    {
      name: intl.formatMessage({
        id: 'applicationTerm.targetLevel',
      }),
      value: 'targetLevel',
    },
  ]

  const checkChecked = (filter: keyof typeof selectedFilters, value: string) => {
    if (selectedFilters && selectedFilters[filter] !== null) {
      return selectedFilters[filter]?.includes(value)
    }
    return false
  }
  const setChecked = (filter: keyof typeof selectedFilters, value: string) => {
    dispatch(setHookmanFilters({ filter, value }))
  }

  if (!levelOptions || !actionOptions) return null

  return (
    <>
      {levelOptions?.length > 0 &&
        filtersOptions.map((filter, index) => (
          <Grid item xs={4} sm={4} md={4} lg={3} key={index}>
            <Box>
              <Typography gutterBottom>{filter.name}</Typography>
              <FormGroup sx={{ ml: 0.5, mt: 1.5 }}>
                {levelOptions.map(option => {
                  const isChecked = checkChecked(filter.value, option.value as string)
                  return (
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          size="small"
                          onChange={() =>
                            setChecked(filter.value, option.value as string)
                          }
                          icon={<UncheckedIcon />}
                          checkedIcon={<CheckedIcon />}
                        />
                      }
                      label={option.label}
                      key={option.value}
                    />
                  )
                })}
              </FormGroup>
            </Box>
          </Grid>
        ))}
    </>
  )
}

export default LevelsActions
