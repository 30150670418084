import RequireUser from 'components/layout/RequireUser'
import ApplicationWrapper from 'components/layout/ApplicationWrapper'
import RequireAuth from 'components/RequireAuth'
import { Route, Routes } from 'react-router-dom'
import StaffMembers from 'routes/StaffMembers'
import HookmanQualifiedList from 'routes/HookmanQualifiedList'
import TrainingCentre from 'routes/TrainingCentre'
import BatteryList from 'routes/BatteryList'
import Dashboard from 'routes/Dashboard'
import EVLevel from 'routes/EVLevel'
import HelpCenter from 'routes/HelpCenter'
import Login from 'routes/Login'
import Logout from 'routes/Logout'
import ResetPassword from 'routes/ResetPassword'
import Settings from 'routes/Settings'
import UpdatePassword from 'routes/UpdatePassword'
import UserDetails from 'routes/UserDetails'
import Nomination from 'routes/Nomination'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

function AppRoutes() {
  const intl = useIntl()
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {intl.formatMessage({ id: 'appNavigation.menuItem.JlrExcellence' })}
        </title>
      </Helmet>
      <Routes>
        <Route path="/" element={<ApplicationWrapper />}>
          <Route element={<RequireUser />}>
            <Route
              index
              element={
                <RequireAuth>
                  <TrainingCentre />
                </RequireAuth>
              }
            />
            <Route path="/ev-level" element={<EVLevel />} />
            <Route path="/ev-level/:id" element={<EVLevel />} />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route
              path="/staff-members"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <StaffMembers />
                </RequireAuth>
              }
            />
            <Route
              path="/view-safety-accompanying-person-list"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <HookmanQualifiedList />
                </RequireAuth>
              }
            />
            <Route
              path="/staff-members/:id"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <StaffMembers />
                </RequireAuth>
              }
            />
            <Route
              path="/user-details/:user"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <UserDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/user-details/:user/:id"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <UserDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <Settings />
                </RequireAuth>
              }
            />
            <Route
              path="/dashboard"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path="/dashboard/battery-list"
              element={
                <RequireAuth restrictTo={['isManager', 'isRetailerAdmin']}>
                  <BatteryList />
                </RequireAuth>
              }
            />
            <Route path="/review-nomination/:id" element={<Nomination />} />
          </Route>
        </Route>
        <Route
          path="/update-password"
          element={
            <RequireAuth ignoreUserData>
              <UpdatePassword />
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/change-forgotten-password" element={<ResetPassword />} />
      </Routes>
    </>
  )
}

export default AppRoutes
