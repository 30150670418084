// Redux business units feature slice to store all global user state related data.

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IHookmanState } from 'interfaces'
import { RootState } from 'store'

const initialState: IHookmanState = {
  filters: {
    certifiedLevel: [],
    workingLevel: [],
    targetLevel: [],
  },
  jobRoles: [],
  searchString: '',
  table: {
    page: 1,
    pageSize: 10,
  },
}

const hookmanListSlice = createSlice({
  name: 'hookmanListSlice',
  initialState: initialState,
  reducers: {
    resetHookmanFilters: () => initialState,
    setHookmanFilters(
      state,
      action: PayloadAction<{
        filter: keyof IHookmanState['filters']
        value: string
      }>,
    ) {
      const { filter, value } = action.payload
      const filterValue = state.filters[filter]
      if (filterValue) {
        if (filterValue.includes(value)) {
          state.filters[filter] = filterValue.filter(item => item !== value)
        } else {
          state.filters[filter] = [...filterValue, value]
        }
      } else {
        state.filters[filter] = [value]
      }
    },
    setHookmanJobRole(state, action: PayloadAction<string[]>) {
      state.jobRoles = action.payload
    },
    setHookmanSearchString(state, action: PayloadAction<string>) {
      state.searchString = action.payload
    },
    setHookmanTableConfig(state, action: PayloadAction<IHookmanState['table']>) {
      const { page, pageSize } = action.payload
      state.table.page = page
      state.table.pageSize = pageSize
    },
  },
})

export const {
  setHookmanFilters,
  setHookmanJobRole,
  setHookmanSearchString,
  resetHookmanFilters,
  setHookmanTableConfig,
} = hookmanListSlice.actions

export const selectHookmanTableConfig = (state: RootState) => state.hookmanList.table
export const selectHookmanSelectedFilters = (state: RootState) =>
  state.hookmanList.filters
export const selectHookmanSelectedJobRole = (state: RootState) =>
  state.hookmanList.jobRoles
export const selecHookmanSelectedSearchString = (state: RootState) =>
  state.hookmanList.searchString

export default hookmanListSlice.reducer
